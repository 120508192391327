import { Route, Navigate } from 'react-router-dom'
import { useAuthContext } from '@/common/context/useAuthContext'

const PrivateRoute = ({ element: Component, ...rest }: any) => {
    const { isAuthenticated } = useAuthContext()
    return (
        <Route
            {...rest}
            element={
                isAuthenticated ? (
                    Component
                ) : (
                    <Navigate
                        to={{
                            pathname: '/auth/login',
                        }}
                        replace
                    />
                )
            }
        />
    )
}

export default PrivateRoute
