import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {
    authProtectedFlattenRoutes,
    publicProtectedFlattenRoutes,
} from './index';
import { useThemeContext } from '../common/context';
import { useAuthContext } from '../common/context/useAuthContext';

const AllRoutes = () => {
    const { settings } = useThemeContext();
    const { isAuthenticated } = useAuthContext();

    const Layout =
        settings.layout.type === 'vertical'
            ? React.lazy(() => import('../Layouts/Vertical'))
            : React.lazy(() => import('../Layouts/Horizontal'));

    return (
        <React.Suspense fallback={<div>Loading...</div>}>
            <Routes>
                {/* Public Routes */}
                {publicProtectedFlattenRoutes.map((route, idx) => (
                    <Route path={route.path} element={route.element} key={idx} />
                ))}

                {/* Auth Protected Routes */}
                {authProtectedFlattenRoutes.map((route, idx) => (
                    <Route
                        path={route.path}
                        element={
                            isAuthenticated ? (
                                <Layout>{route.element}</Layout>
                            ) : (
                                <Navigate to="/auth/login" />
                            )
                        }
                        key={idx}
                    />
                ))}
            </Routes>
        </React.Suspense>
    );
};

export default AllRoutes;
