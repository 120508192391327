export interface RoutesProps {
    path: string;
    name?: string;
    element?: React.ReactNode;
    route?: any;
    exact?: boolean;
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

import { authRoutes } from './authRoutes';
import { protectedRoutes } from './protectedRoutes';
import { flattenRoutes } from './flattenRoutes';

export const authProtectedFlattenRoutes = flattenRoutes(protectedRoutes);
export const publicProtectedFlattenRoutes = flattenRoutes(authRoutes);
