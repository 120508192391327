// src/common/useAuthContext.tsx

import {
    createContext,
    useContext,
    useState,
    useCallback,
    ReactNode,
} from 'react';
import { User } from '@/types'; // Importa o tipo User

// Cria o contexto de autenticação
const AuthContext = createContext<any>({});

// Hook para usar o contexto de autenticação
export function useAuthContext() {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within an AuthProvider');
    }
    return context;
}

// Chave de sessão para armazenar os dados de autenticação no localStorage
const authSessionKey = '_SisCount_AUTH';

// Provedor de autenticação
export function AuthProvider({ children }: { children: ReactNode }) {
    // Estado para armazenar o usuário autenticado, inicializa com os dados do localStorage se disponíveis
    const [user, setUser] = useState<User | undefined>(
        localStorage.getItem(authSessionKey)
            ? JSON.parse(localStorage.getItem(authSessionKey) || '{}')
            : undefined
    );

    // Função para salvar a sessão, armazenando os dados do usuário no localStorage
    const saveSession = useCallback(
        (user: User) => {
            localStorage.setItem(authSessionKey, JSON.stringify(user));
            setUser(user);
        },
        [setUser]
    );

    // Função para remover a sessão, limpando os dados do usuário do localStorage
    const removeSession = useCallback(() => {
        localStorage.removeItem(authSessionKey);
        setUser(undefined);
    }, [setUser]);

    // Função para obter o token de autenticação do usuário
    const getToken = useCallback(() => {
        return user?.token;
    }, [user]);

    // Provedor do contexto de autenticação, fornecendo funções e estado para os componentes filhos
    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated: Boolean(user), // Verifica se o usuário está autenticado
                saveSession,
                removeSession,
                getToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}
