// main.tsx

import App from './App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import 'regenerator-runtime/runtime'
import Env from './utils/env'

if (import.meta.env.MODE === 'development') {
	console.log(
		`${Env.projectData('title')}\n\t` +
		`-> version: ${Env.projectData('version')} in ${Env.projectData('date')}\n\t` +
		`-> by ${Env.projectData('author')?.name}`
	)
	console.log(`API URL being used: ${import.meta.env.VITE_API_URL}`)
}

const container = document.getElementById('root')
if (container) {
	const root = createRoot(container)
	root.render(
		// <React.StrictMode>
		<BrowserRouter basename={''}>
			<App />
		</BrowserRouter>
		// </React.StrictMode>,
	)
}
