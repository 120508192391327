// authRoutes.tsx
import React from 'react';
import { Route } from 'react-router-dom';

const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));
const MaintenancePages = React.lazy(() => import('../pages/other/Maintenance'));

export const authRoutes = [
    { path: '/auth/login', element: <Login />, route: Route },
    { path: '/auth/register', element: <Register />, route: Route },
    { path: '/auth/logout', element: <Logout />, route: Route },
    { path: '/auth/forgot-password', element: <ForgotPassword />, route: Route },
    { path: '/auth/lock-screen', element: <LockScreen />, route: Route },
    { path: '*', element: <Error404 />, route: Route },
    { path: 'pages/error-404', element: <Error404 />, route: Route },
    { path: 'pages/error-500', element: <Error500 />, route: Route },
    { path: '/pages/maintenance', element: <MaintenancePages />, route: Route },
];
