import { RoutesProps } from './index'; // Assuming RoutesProps is defined in index.ts

export const flattenRoutes = (routes: RoutesProps[]): RoutesProps[] => {
    let flatRoutes: RoutesProps[] = [];

    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);
        if (item.children) {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });

    return flatRoutes;
};
